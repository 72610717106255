import * as React from "react";
import {TextField} from "@material-ui/core";
import {CountrySelector} from "./CountrySelector";

export const AddressFields = ({handleChange, state, setState}) => {
    return (
        <fieldset>
            <TextField autoComplete="firstname" fullWidth id="firstname" label="First name" margin="normal" name="firstname" onChange={handleChange} required value={state.firstname} variant="outlined" />
            <TextField autoComplete="surname" fullWidth id="surname" label="Surname / Last name" margin="normal" name="surname" onChange={handleChange} required value={state.surname} variant="outlined" />
            <TextField autoComplete="company" fullWidth id="company" label="Company (optional)" margin="normal" name="company" onChange={handleChange} value={state.company} variant="outlined" />
            <TextField autoComplete="address1" fullWidth id="address1" label="Address line 1" margin="normal" name="address1" onChange={handleChange} required value={state.address1} variant="outlined" />
            <TextField autoComplete="address2" fullWidth id="address2" label="Address line 2" margin="normal" name="address2" onChange={handleChange} value={state.address2} variant="outlined" />
            <TextField autoComplete="city" fullWidth id="city" label="City" margin="normal" name="city" onChange={handleChange} required value={state.city} variant="outlined" />
            <TextField autoComplete="county" fullWidth id="county" label="County/State" margin="normal" name="county" onChange={handleChange} required value={state.county} variant="outlined" />
            <TextField autoComplete="postcode" fullWidth id="postcode" label="Postal/Zip code" margin="normal" name="postcode" onChange={handleChange} required value={state.postcode} variant="outlined" />
            <CountrySelector countryCode={state.country} required setCountryCode={(countryCode) => {setState({ ...state, country: countryCode })}} />
            <TextField autoComplete="phone" fullWidth id="phone" label="Phone (main)" margin="normal" name="phone" onChange={handleChange} required value={state.phone} variant="outlined" />
        </fieldset>
    );
};
