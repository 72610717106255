import * as React from 'react';
import {SEO} from "../../components/seo";
import {Grid} from "@material-ui/core";

export function NotFound() {
    return (
        <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="center"
            spacing={0}
            style={{ minHeight: `50vh` }}
        >
            <SEO title="404: Not found" />
            <Grid item xs={3}>
                <h2 className="text-2xl inline-block my-8 p-3">
                    Whoops, this page has vanished. Sorry, we'll try to track it down...
                </h2>
            </Grid>
        </Grid>
    )
}
