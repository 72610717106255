/* Commerce Layer integration */
import {directusClient} from "../../hooks/useDirectus";

export const getAddress = async (id) => {
    const endpoint = id ? `/custom/addresses/${id}` : `/custom/addresses`;
    const {data: address} = await directusClient.get(endpoint);
    return address;
}

export const createAddress = async (data) => {
    const {data: address} = await directusClient.post(`/custom/addresses`, data);
    return address;
}

export const updateAddress = async ({ id, updates }) => {
    const {data: address} =  await directusClient.patch(`/custom/addresses/${id}`, updates);
    return address;
}

export const deleteAddress = ({ id }) => {
    if (id < 1 ) { return false; }
    return directusClient.delete(`/custom/addresses/${id}`);
}

export const createCustomer = async ({ email }) => {
    const { data } = await directusClient.post(`/custom/customers`, { email });
    return data;
}

export const updateCustomer = async ({ id, updates }) => {
    const { data } =  await directusClient.patch(`/custom/customers/${id}`, updates);
    return data;
}

export const getCustomerAddresses = async () => {
    const {data: address} = await directusClient.get(`/custom/customer_addresses`);
    return address;
}

export const createCustomerAddress = async ({ customerId, addressId }) => {
    const {data: customerAddress} = await directusClient.post(`/custom/customer_addresses`, { customer_id: customerId, address_id: addressId });
    return customerAddress;
}

export const deleteCustomerAddress = ({ id }) => {
    if (id < 1 ) { return false; }
    return directusClient.delete(`/custom/customer_addresses/${id}`);
}
