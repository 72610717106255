import * as React from "react";
import {ListItem, Typography} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {CardImage} from "../cards/CardImage";
import {useCard} from "../cards/hooks";
import {splitSku} from "../products/utils";
import {cardStyleByLetter} from "../../utils/card-style";
import {cardCondition} from "../../utils/card-condition";
import {FlagIcon} from "../../components/FlagIcon";
import {Link} from "gatsby";

export const OrderItem = ({ item }) => {
    const cardId = splitSku(item.attributes.sku_code).cardId;
    const {isLoading, data:card} = useCard(cardId);

    return item && !isLoading && card ? (
        <ListItem>
            <Link to={`/card/${card.data.cardId}`} >
                <CardImage alt={card.data.name} assetUrl={card.data.image.data.asset_url} fullUrl={card.data.imageUrl} height={105} width={75} />
            </Link>
            <div className="flex flex-col ml-4 self-start">
                <Typography className={`truncate`} variant="h6">
                    {card.data.name}
                </Typography>
                <ul>
                    <li className="capitalize">Quantity: {item.attributes.quantity}</li>
                    <li className="capitalize">Style: {cardStyleByLetter[splitSku(item.attributes.sku_code).style]}</li>
                    <li className="capitalize">Condition: {cardCondition[splitSku(item.attributes.sku_code).conditionId]}</li>
                    <li className="capitalize">Language: <FlagIcon code={splitSku(item.attributes.sku_code).language} /></li>
                    <li className="capitalize">Signed: {splitSku(item.attributes.sku_code).signed ? `Yes` : `No`}</li>
                </ul>
            </div>
        </ListItem>
    ) : <Skeleton animation="pulse" height={165} variant="rect" />;
};
