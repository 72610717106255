import * as React from 'react';
import {Link} from "gatsby";
import {Button, Grid} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import {Skeleton} from "@material-ui/lab";

import {useCustomerAddresses} from "./hooks_cl";
import {Heading} from "../../components/heading";
import {Address} from "../checkout/Address";

export const AddressManager = ({ addressType, handleUseAddress }) => {
    const {isLoading, data} = useCustomerAddresses();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Heading text="Addresses" />
            </Grid>
            {!isLoading && data ? <>
                <Grid className={`flex flex-wrap`} item xs>
                    {data && data.included && data.included.map(address => (
                        <div className={`mb-4 md:mr-4 w-64`} key={address.id}>
                            <Address data={address} />
                            <div className={`mt-2`}>
                                <Link to={`/checkout/address/edit/${address.id}`}>
                                    <Button color="secondary" size="small" variant="outlined">Edit</Button>
                                </Link>
                                <Button className={`ml-2`} color="primary" onClick={()=>handleUseAddress(address.id)} size="small" variant="contained">Use</Button>
                            </div>
                        </div>
                    ))}
                    <div className={`my-auto pb-4`}>
                        <Link className={`block my-6 w-64`} to={`/checkout/address/create/${addressType}`}>
                            <Button aria-label="add address" color="secondary" startIcon={<AddIcon />} variant="outlined">Add new</Button>
                        </Link>
                    </div>
                </Grid>
            </> : <Grid item xs={12}><Skeleton animation="pulse" height={175} variant="rect" /></Grid>}
        </Grid>
    )
}
