import * as React from 'react';

export const Address = ({ data }) => {
    return data ? (
        <p>
            {data.attributes.first_name && (<>{data.attributes.first_name} {data.attributes.last_name}<br/></>)}
            {data.attributes.company && (<>{data.attributes.company}<br/></>)}
            {data.attributes.line_1} {data.attributes.line_2}<br/>
            {data.attributes.city}, {data.attributes.state_code}, {data.attributes.zip_code}<br/>
            {data.attributes.country_code}<br/>
            {data.attributes.phone}
        </p>
    ) : null;
};
