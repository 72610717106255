import * as React from 'react';
import {Link} from "gatsby";
import {Button, Grid, Paper} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {Heading} from "../../../components/heading";
import {OrderDetails} from "../../orders/OrderDetails";
import {useCart} from "../../cart/hooks";

export const SuccessScreen = ({ orderId }) => {
    const { isLoading: isLoadingCart, data: cart} = useCart();

    return (
        <section>
            {isLoadingCart ? <Skeleton animation="pulse" height={100} variant="rect" /> :
                cart && cart.data && cart.data.length > 0 && (
                <Paper className={`mb-4 p-4`}>
                    <Heading text={`${cart.data.length} Pending orders`} />
                    <Grid container>
                        <p>Your cart has items awaiting payment. <Link className={`text-primary`} to={`/checkout`}>Check out now</Link>.</p>
                    </Grid>
                </Paper>
            )}
            <Paper className={`max-w-3xl min-h-screen p-4`}>
                <OrderDetails orderId={orderId} />
                <div className="mt-4">
                    <Button className="mt-4 mr-4" color="primary" component={Link} to="/marketplace" variant="outlined">Continue shopping</Button>
                    <Button className="mt-4 mr-4" color="primary" component={Link} to="/dashboard" variant="outlined">Order history</Button>
                </div>
            </Paper>
        </section>
    )
};
