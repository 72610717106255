import * as React from 'react';
import {Router} from '@reach/router';
import {StringParam, useQueryParams} from "use-query-params";
import {useQueryClient} from "react-query";
import {navigate} from "gatsby";

import {SEO} from "../../components/seo";
import {Layout} from "./Layout";
import {useAuth} from "../../context/authContext";
import {useUIDispatch} from "../../app/uiContext";
import {AddressScreen} from "./screens/address";
import {ReviewScreen} from "./screens/review";
import {SuccessScreen} from "./screens/success";
import {NotFound} from "../../dashboard/screens/not-found";
import {Spinner} from "../../components/Spinner";
import {orderKeys, useOrder, useOrderPaymentSource, useUpdateOrder, useUpdatePaypalPayment} from "../orders/hooks";

const PayPalAuth = ({ orderId }) => {
    const queryClient = useQueryClient();
    const [query] = useQueryParams({
        token: StringParam,
        PayerID: StringParam,
    });
    const { isLoading: isLoadingOrder, data: order } = useOrder(orderId)
    const { isIdle: isIdleOrderMutation, mutate:mutateOrder } = useUpdateOrder();
    const { isLoading: isLoadingPaymentSource, data: paymentSource } = useOrderPaymentSource(orderId)
    const { isIdle: isIdlePayPalPaymentMutation, mutate:mutatePaypalPayment } = useUpdatePaypalPayment();

    React.useEffect(() => {
        if (isIdleOrderMutation && isIdlePayPalPaymentMutation && !isLoadingOrder && order && !isLoadingPaymentSource && paymentSource) {
            if ([`draft`,`cancelled`].includes(order.data.attributes.status)) {
                navigate(`/dashboard`);
            } else if (order.data.attributes.status === `pending`) {
                mutatePaypalPayment({id:paymentSource.data.id, updates: {paypal_payer_id:query.PayerID}}, {
                    onSuccess: (data, variables) => {
                        mutateOrder({id:orderId, updates:{_place:true}}, {
                            onSuccess: () => {
                                mutateOrder({id:orderId, updates:{_approve_and_capture:true}}, {
                                    onSuccess: () => {
                                        queryClient.invalidateQueries(orderKeys.detail(orderId));
                                        navigate(`/checkout/success/${orderId}`);
                                    }
                                })
                            }
                        })
                    }
                });
            } else if (order.data.attributes.status === `placed`) {
                mutateOrder({id:orderId, updates:{_approve_and_capture:true}}, {
                    onSuccess: () => {
                        queryClient.invalidateQueries(orderKeys.detail(orderId));
                        navigate(`/checkout/success/${orderId}`);
                    }
                });
            } else if (order.data.attributes.status === `approved`) {
                navigate(`/checkout/success/${orderId}`);
            }
        }
    },[isIdleOrderMutation, isIdlePayPalPaymentMutation, isLoadingOrder, isLoadingPaymentSource, mutateOrder, mutatePaypalPayment, order, orderId, paymentSource, query, queryClient])

    return <Spinner className={`my-12`} />;
}

export const Checkout = () => {
    const { isLoggedIn } = useAuth();
    const uiDispatch = useUIDispatch();

    React.useEffect(() => {
        if (!isLoggedIn()) { uiDispatch({type:`SHOW_LOGIN`}); }
    }, [isLoggedIn, uiDispatch]);

    return isLoggedIn() ? (
        <Layout>
            <SEO title="Checkout" />
            <Router basepath="/checkout">
                <ReviewScreen path="/" />
                <AddressScreen path="address/edit/:id" />
                <AddressScreen path="address/create/:type" />
                <SuccessScreen path="success/:orderId" />
                <PayPalAuth path="paypal-auth/:orderId" />
                <NotFound default />
            </Router>
        </Layout>
    ) : null
};
